import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCategories } from '../api'
const Navbar = () => {
    const [categories, setCategories] = useState([])
    const [activeNavbar, setActiveNavbar] = useState(false)
    useEffect(() => {
        const callNow = async () => {
            const { data } = await getCategories();
            if (data.success) {
                setCategories(data.data);
            }
        }
        callNow();
    }, [])
    return (
        <>
            {/* <!-- ============= Header =============== --> */}
            <header>
                <div className="container">
                    <div className="d-flex py-2 justify-content-between align-items-center">
                        <img src="/images/Menu.png" alt="lets_talk" className="button-talk_icon" onClick={e => setActiveNavbar(true)} />
                        <Link to={'/'}>
                            <img src="/images/logo.png" alt="logo" className="img-fluid logo " />
                        </Link>
                        <div className="d-flex align-items-center">
                            <ul className={`header-nav-ul ${activeNavbar && 'show'}`}>
                                <li onClick={e => setActiveNavbar(false)}>
                                    <Link to={'/'}>
                                        Home
                                    </Link>
                                </li>
                                <li className="dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Collections
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light">
                                        {
                                            categories.map(item => {
                                                return (
                                                    <li key={item._id}  onClick={e => setActiveNavbar(false)}><Link className="dropdown-item" to={`/collection/${item._id}`}>{item.name}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                <li onClick={e => setActiveNavbar(false)}><Link to={'/aboutus'}>About Us</Link></li>
                                <li onClick={e => setActiveNavbar(false)}><Link to={'/contactus'}>Contact Us</Link></li>
                            </ul>
                            <Link to={'/contactus'} className="button-talk">Let's Talk</Link>
                            <Link to={'/contactus'} ><img src="/images/lets_talk.png" alt="lets_talk" className="button-talk_icon" /></Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar