import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { dashboard } from './api';
import EnquiryNowModel from './components/EnquiryNowModel';
import AboutUs from './pages/AboutUs';
import Collections from './pages/Collections';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import ProductDetail from './pages/ProductDetail';
import Terms from './pages/Terms';

function App() {
    const [dashBoardData, setDashBoardData] = useState({})
    const [loader, setLoader] = useState(true)
    useEffect(() => {
      const callNow = async () => {
        const {data} = await dashboard()
        setDashBoardData(data.data)
    }
      callNow()
    }, [])
    
    useEffect(() => {
      setTimeout(() => {
        setLoader(false)
      }, 4000);
    }, [])
    
    return (
        <>
            <Router>
                <a href={dashBoardData?.whatsapp} className="wp_icon" target="_blank">
                    <img src="/images/wp_icon.png" alt="wp" />
                </a>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
                <div className={`loader ${!loader && 'stopLoader'}`}>
                    <img src="/images/loader.gif" alt="loader" className='loaderImage'/>
                </div>
                <EnquiryNowModel />
                <Routes>
                    <Route path='/' element={<Home dashboard={dashBoardData}/>}></Route>
                    <Route path='/aboutus' element={<AboutUs dashboard={dashBoardData}/>}></Route>
                    <Route path='/contactus' element={<ContactUs dashboard={dashBoardData}/>}></Route>
                    <Route path='/collection/:id' element={<Collections dashboard={dashBoardData}/>}></Route>
                    <Route path='/product/:slug' element={<ProductDetail dashboard={dashBoardData}/>}></Route>
                    <Route path='/terms' element={<Terms dashboard={dashBoardData}/>}></Route>
                    <Route path='/privacy' element={<Privacy dashboard={dashBoardData}/>}></Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
