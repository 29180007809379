import React from 'react'

const Details = () => {
  return (
//    <!-- =============== Details =============== -->
    <section className="details m-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 my-2">
                    <div className="d-flex flex-row flex-sm-column align-items-center justify-content-start justify-content-sm-center align-items-sm-start">
                        <div className="detail-icon d-flex align-items-center">
                            <img src="/images/trust.png" alt="trust" className="img-fluid details-img" />
                        </div>
                        <h2 className="text-white my-0 my-sm-2 fw-bold ms-2 ms-md-0 w-100">Reliable & Trusted By Global Brands</h2>
                    </div>
                    <p className="text-white fw-light lh-base">Trust developed by holding Distribution right of over 15 brands across world, in the market. And recognised as one of the Biggest and Largest Distributors of South India.</p>
                </div>
                <div className="col-lg-3 col-md-6 my-2">
                    <div className="d-flex flex-row flex-sm-column align-items-center justify-content-start justify-content-sm-center align-items-sm-start">
                        <div className="detail-icon d-flex align-items-center">
                            <img src="/images/experience.png" alt="experience" className="img-fluid details-img" />
                        </div>
                        <h2 className="text-white my-0 my-sm-2 fw-bold ms-2 ms-md-0 w-100">7 Years + Experience</h2>
                    </div>
                    <p className="text-white fw-light lh-base">2555 days of building trust and recognition with a rapid shift towards technological advancement with the features in the market with leading professionals in audio sector.</p>
                </div>
                <div className="col-lg-3 col-md-6 my-2">
                    <div className="d-flex flex-row flex-sm-column align-items-center justify-content-start justify-content-sm-center align-items-sm-start">
                        <div className="detail-icon d-flex align-items-center">
                            <img src="/images/support.png" alt="support" className="img-fluid details-img" />
                        </div>
                        <h2 className="text-white my-0 my-sm-2 fw-bold ms-2 ms-md-0 w-100">5-Star Customer Support</h2>
                    </div>
                    <p className="text-white fw-light lh-base">Instant responses from the professional on any queries with experience of over 7 years in the industry providing expertise to every field of service.</p>
                </div>
                <div className="col-lg-3 col-md-6 my-2">
                    <div className="d-flex flex-row flex-sm-column align-items-center justify-content-start justify-content-sm-center align-items-sm-start">
                        <div className="detail-icon d-flex align-items-center">
                            <img src="/images/feedback.png" alt="feedback" className="img-fluid details-img" />
                        </div>
                        <h2 className="text-white my-0 my-sm-2 fw-bold ms-2 ms-md-0 w-100">Appreciative and Happy Feedbacks</h2>
                    </div>
                    <p className="text-white fw-light lh-base">Over the years we have always strived to make sure the end-user (customer) is satisfied, in terms of service and products</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Details