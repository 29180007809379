import React, { useEffect, useState } from 'react'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import Navbar from '../components/Navbar'
import Products from '../components/Products'
import { useParams } from 'react-router-dom'
import { products } from '../api'
import { toast } from 'react-hot-toast'

const Collections = ({dashboard}) => {
  const [category, setCategory] = useState({})
  const [allProducts, setAllProducts] = useState([])
  const { id } = useParams();
  useEffect(() => {
    const callMe = async () => {
      try {
        const { data } = await products(id)
        if (data.success) {
          setCategory(data.category)
          setAllProducts(data.data)
        } else {
          toast.error(data.msg)
        }
      } catch (err) {
        toast.error(err.message)
      }
    }
    callMe();
  }, [id])
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      {/* ---------- Navbar -------------- */}
      <Navbar />
      {/* ---------- Banner --------------- */}
      <section>
        <div className="collection-main-banner" style={{ backgroundImage: "url('/images/collection_banner.png')" }}>
          <div className="container h-100">
            <div className="d-flex flex-column justify-content-center h-100">
              <div className="col-lg-10">
                <h3 className="text-white fw-bold">Explore Premium</h3>
                <h1 className="display-4 fw-bold text-white">{category?.name}</h1>
                <p className="text-white lh-lg">{category?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------- Products ------------ */}
      <section className="m-100">
        {
          allProducts ?
            <>
              <Products allProducts={allProducts} />
            </>
            : ""
        }
      </section>
      {/* ------------- Details -------------- */}
      <Details />
      {/* ------------- Footer -------------- */}
      <Footer dashboard={dashboard}/>
    </>
  )
}

export default Collections