import React from 'react'
import { Link } from 'react-router-dom'

const SingleProduct = ({product}) => {
    return (
        <div className="col-lg-3 col-6">
            <Link to={`/product/${product.slug}`}>
            <div className="product_img">
                <img src={product.photos[0]} alt="product_image" className="img-fluid" />
            </div>
            <h2 className="fw-bold px-2 text-dark">{product.title}</h2>
            <h6 className="lh-base px-2 text-dark">{product.shortDescription.slice(0,100)}...</h6>
            </Link>
        </div>
    )
}

export default SingleProduct