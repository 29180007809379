import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { activatedproducts, getCategories } from '../api'
import Applications from '../components/Applications'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import Navbar from '../components/Navbar'
import Products from '../components/Products'
import Series from '../components/Series'

const Home = ({ dashboard }) => {
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const callNow = async () => {
            const { data } = await getCategories()
            if (data.success) {
                setCategories(data.data)
            }
        }
        callNow();
    }, [])
    useEffect(() => {
        const callNow = async () => {
            const { data } = await activatedproducts();
            if (data.success) {
                setProducts(data.data)
            }
        }
        callNow();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            {/* <!-- ============ Main Banner ============ --> */}
            {
                dashboard.mainBanner &&
                <>
                    <section className='big_image'>
                        <div className="main-banner position-relative" style={{ backgroundImage: `url(${dashboard?.mainBanner[0]})` }}>
                            <div className="container text-white fw-bold">
                                <div className="main-banner-text">
                                    <div className="col-md-8">
                                        <h3 className="fw-bold">Welcome to</h3>
                                        <h2 className="fw-bold display-4">D-Force Power</h2>
                                        <p className="lh-lg">D’FORCEpower is devoted to the designing, manufacturing, marketing, institution, installation, servicing, lending and distribution of professional audio/video equipments suited for the Indian market.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container h-100">
                                <div className="d-flex justify-content-center h-100 align-items-end w-100 overflow-auto">
                                    {
                                        categories.map(item => {
                                            return (
                                                <Link key={item._id} to={`/collection/${item._id}`} className="  text-white p-2 text-center py-3 px-3" style={{'whiteSpace':"nowrap"}}>
                                                        {item.name}
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='small_image'>
                        <div className="main-banner position-relative" style={{ backgroundImage: `url(${dashboard?.mainBanner[1]})` }}>
                            <div className="container text-white fw-bold">
                                <div className="main-banner-text">
                                    <div className="col-md-8">
                                        <h3 className="fw-bold">Welcome to</h3>
                                        <h2 className="fw-bold display-4">D-Force Power</h2>
                                        <p className="lh-lg">D’FORCEpower is devoted to the designing, manufacturing, marketing, institution, installation, servicing, lending and distribution of professional audio/video equipments suited for the Indian market.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container h-100 overflow-auto">
                                <div className="d-flex justify-content-start h-100 align-items-end w-100 overflow-auto" >
                                    {
                                        categories.map(item => {
                                            return (
                                                <Link key={item._id} to={`/collection/${item._id}`} className="  text-white p-2 text-center py-3 px-3" style={{'whiteSpace':"nowrap"}}>
                                                        {item.name}
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }

            <div className="bg-dark">
            </div>
            {/* <!-- ============= Products ============== --> */}
            <section>
                {/* <!-- ------------- heading ------------- --> */}
                <Heading heading={"Can't Pick One ?"} subHeading={'Here are our featured favourites'}></Heading>
                {/* <!-- ----------------------------------- --> */}
                <Products allProducts={products} />
            </section>
            {/* ================== Details ================ */}
            <Details />
            {/* ================== Application ================ */}
            <Applications dashboard={dashboard} />
            {/* ================== Video Section ================ */}
            <section className="details m-100 mb-0">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-lg-6 order-1 order-lg-0">
                            <h2 className="fw-bold text-white text-center text-lg-start">It’s Going To Get Loud, Now!</h2>
                            <h5 className="text-white lh-lg text-center text-lg-start">The only drivers that are made in line with the harsh Indian weather and rough on road ruggedness. The drivers are known to endure abuse when it comes to be played all day long in rallies and round the clock pandals in worst climate one can imagine. </h5>
                        </div>
                        <div className="col-lg-6">
                            <video src="/images/DFORCEpower-Speaker-Loop.mp4" className="loop-video img-fluid" loop autoPlay={true}></video>
                        </div>
                    </div>
                </div>
            </section>
            {/* ================= Series ================ */}
            <Series dashboard={dashboard} />
            {/* ================== Testimonial ================= */}
            <section>
                {/* <!-- ------------- heading ------------- --> */}
                <Heading heading={'Testimonial'} subHeading={'What our client are saying...'} />
                {/* <!-- ----------------------------------- --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 my-3">
                            <div className="testimonial-container p-3">
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <div className="testimonial-image">
                                            <img src="/images/avatar_3.png" alt="client" className="img-fluid" />
                                        </div>
                                        <img src="/images/star.png" alt="star" className="mt-2 img-fluid" />
                                    </div>
                                    <div className="ms-2">
                                        <p className="mb-1">Sound Is Amazing</p>
                                        <p className="mb-2">Wonderful packaging, accessories and even a instruction booklet. This
                                            product is
                                            easily worth its price</p>
                                        <h5 className="fw-bold text-end me-3">- Jaimin Patel</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3">
                            <div className="testimonial-container p-3">
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <div className="testimonial-image">
                                            <img src="/images/avatar_2.png" alt="client" className="img-fluid" />
                                        </div>
                                        <img src="/images/star.png" alt="star" className="mt-2 img-fluid" />
                                    </div>
                                    <div className="ms-2">
                                        <p className="mb-1">Sound Is Fabulous</p>
                                        <p className="mb-2">If efficiency means something to you. who makes these Speakers that I really like</p>
                                        <h5 className="fw-bold text-end me-3">- Rajesh Manglani</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3">
                            <div className="testimonial-container p-3">
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <div className="testimonial-image">
                                            <img src="/images/avatar_1.png" alt="client" className="img-fluid" />
                                        </div>
                                        <img src="/images/star.png" alt="star" className="mt-2 img-fluid" />
                                    </div>
                                    <div className="ms-2">
                                        <p className="mb-1">I like it so much</p>
                                        <p className="mb-2">I highly recommend the DForcePower. It is working flawlessly , Sounds Great in this</p>
                                        <h5 className="fw-bold text-end me-3">- Ritesh Kapoor</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ========== You tube part ========= --> */}
            <section>
                <iframe className="youtube" src={dashboard.youtubeEmbed} title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            </section>
            {/* =============== Footer ================== */}
            <Footer dashboard={dashboard} />
        </>
    )
}

export default Home