import axios from 'axios';

const api = axios.create({
    // baseURL:"http://localhost:5000/api",
    baseURL:"https://admin.dforcepower.com/api",
    headers:{
        Accept:"application/json",
        "Content-Type": "application/json"
    }
})


export const products = (category) => api.get(`/products/${category}`)
export const getCategories = () => api.get('/categories')
export const singleProduct = (product) => api.get(`/product/${product}`)
export const inquiry = (data) => api.post('/inquiry',data)
export const dashboard = () => api.get('/dashboard')
export const activatedproducts = () => api.get('/activatedproducts')