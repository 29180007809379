import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCategories } from '../api'

const Footer = ({ dashboard }) => {
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const callNow = async () => {
            const { data } = await getCategories();
            if (data.success) {
                setCategories(data.data);
            }
        }
        callNow();
    }, [])

    return (
        // <!-- ========= Footer ================ -->
        <footer className="p-3 pt-5 pb-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 my-2">
                        <div className="logo">
                            <img src="/images/logo.png" alt="logo" className='img-fluid' style={{ height: 60,objectFit:"contain" }} />
                        </div>
                        <p className="mt-3 small">{dashboard.footerDescription}</p>
                        <div className="d-flex align-items-center">
                            <a href={dashboard.facebook} target="_blank">
                                <img src="/images/facebook.png" alt="facebook" className="mx-2 ms-0" />
                            </a>
                            <a href={dashboard.instagram} target="_blank">
                                <img src="/images/instagram.png" alt="instagram" className="mx-2" />
                            </a>
                            <a href={dashboard.twitter} target="_blank">
                                <img src="/images/twitter.png" alt="twitter" className="mx-2" />
                            </a>
                            <a href={dashboard.youtube} target="_blank">
                                <img src="/images/youtube.png" alt="linkedin" className="mx-2" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-2">
                        <div className="d-flex justify-content-start justify-content-lg-end">
                            <div>
                                <div className="title">
                                    <h3 className="fw-bold">Categories</h3>
                                </div>
                                <ul className="footer-ul">
                                    {
                                        categories.map(item => {
                                            return (
                                                <li key={item._id}><Link to={`/collection/${item._id}`}>{item.name}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-2">
                        <div className="d-flex justify-content-center">
                            <div>
                                <div className="title">
                                    <h3 className="fw-bold">Pages</h3>
                                </div>
                                <ul className="footer-ul">
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'/aboutus'}>About Us</Link></li>
                                    <li><Link to={'/contactus'}>Contact Us</Link></li>
                                    <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                                    <li><Link to={'/terms'}>Terms</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-2">
                        <div className="title">
                            <h3 className="fw-bold">Contact Us</h3>
                        </div>
                        <p className="mb-2 lh-lg">Nr. Kanya Chatralay, Botad-Lathidad Road, Lathidad, Botad(Gujarat) - 364710</p>
                        <div className="d-flex align-items-center">
                            <div>
                                <img src="/images/phone_icon.png" alt="phone_icon" />
                            </div>
                            <p className="mb-0 ms-2">
                                <a href="tel:+918140867071">
                                    +91 81408 67071
                                </a>
                            </p>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <div>
                                <img src="/images/mail_icon.png" alt="mail_icon" />
                            </div>
                            <a href="mailto:contact@dforcepower.com">
                            <p className="mb-0 ms-2">dforcepower@gmail.com</p>
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="my-4" />
                <div className="row justify-content-between">
                    <div className="col-lg-6">
                        <p className="m-0 small text-center text-lg-start">© Copyright 2015-2022 DFORCEpower - All rights reserved</p>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex justify-content-lg-end justify-content-center">
                            <a href="/privacy" className="footer-end mx-1 small">Privacy Policy</a>
                            <a href="/terms" className="footer-end mx-1 small">Terms & Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer