import React from 'react'
import { Link } from 'react-router-dom'
import SingleProduct from './SingleProduct'

const Products = ({ allProducts }) => {
  console.log(allProducts)
  return (
    <div className="container">
      <div className="row">
        {
          allProducts.map(item => {
            return (
              
                <SingleProduct product={item} key={item._id}/>
            )
          })
        }
      </div>
    </div>
  )
}

export default Products