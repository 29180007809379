import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom'
import { inquiry } from '../api';

const EnquiryNowModel = () => {
    const slug = window.location.href.split('/')[(window.location.href.split('/')).length - 1];
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [mobileNo, setMobileNo] = useState("")

    const submit = async (e) => {
        e.preventDefault();
        const {data} = await inquiry({name,email,subject,message,product:slug,contact:mobileNo});
        if (data.success) {
            document.getElementById('close').click();
            toast.success(data.msg);
        }
        setEmail("")
        setName("")
        setSubject("")
        setMessage("")
        setMobileNo("")
    }
    return (
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Enquiry Now</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close"></button>
                    </div>
                    <div class="modal-body">
                        <form className='container-fluid' onSubmit={submit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label fw-bold">Your Name</label>
                                        <input type="text" class="form-control" value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label fw-bold">Email address</label>
                                        <input type="email" class="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-12" >
                                    <div class="mb-3">
                                        <label class="form-label fw-bold">Mobile No.</label>
                                        <input type="text" class="form-control" value={mobileNo} onChange={e => setMobileNo(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label fw-bold">Subject</label>
                                        <input type="text" class="form-control" value={subject} onChange={e => setSubject(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label fw-bold">Message</label>
                                        <textarea rows="5" className='form-control' onChange={e => setMessage(e.target.value)} value={message}></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button type='submit' className='enquire-btn w-100'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnquiryNowModel