import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { inquiry } from '../api'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import Navbar from '../components/Navbar'
const ContactUs = ({ dashboard }) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const submit = async (e) => {
        e.preventDefault();
        const { data } = await inquiry({ name, email, subject, message, product: "FromContactUs", contact: mobileNo,fromContact:true });
        if (data.success) {
            document.getElementById('close').click();
            toast.success(data.msg);
        }
        setEmail("")
        setName("")
        setSubject("")
        setMessage("")
        setMobileNo("")
    }
    return (
        <>
            {/* ------------- header --------------- */}
            <Navbar />
            {/* ============= Contact Us ============= */}
            {/* --------- Heading ----------- */}
            <Heading heading={'Contact Us'} subHeading={"Here are our contact details"} />
            <div className="container">
                <form onSubmit={submit} className="row">
                    <div className="col-lg-6">
                        <div class="mb-3">
                            <label class="form-label fw-bold">Your Name</label>
                            <input type="text" class="form-control" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="mb-3">
                            <label class="form-label fw-bold">Email address</label>
                            <input type="email" class="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-lg-12" >
                        <div class="mb-3">
                            <label class="form-label fw-bold">Mobile No.</label>
                            <input type="text" class="form-control" value={mobileNo} onChange={e => setMobileNo(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="mb-3">
                            <label class="form-label fw-bold">Subject</label>
                            <input type="text" class="form-control" value={subject} onChange={e => setSubject(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="mb-3">
                            <label class="form-label fw-bold">Message</label>
                            <textarea rows="5" className='form-control' onChange={e => setMessage(e.target.value)} value={message}></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <button type='submit' className='enquire-btn w-100'>Submit</button>
                    </div>
                </form>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <img src="/images/contact_us.png" alt="abotuUS" className='img-fluid p-5' />
                    </div>
                    <div class="col-lg-6 my-3">
                        <div class="d-flex my-3 my-lg-5">
                            <div>
                                <img src="/images/phone.png" alt="phone" />
                            </div>
                            <div class="ms-2">
                                <h5 class="ms-3">Contact No.</h5>
                                <h4 class=" contact-detail"><a href="tel:+918140867071">+91 81408 67071</a></h4>
                            </div>
                        </div>
                        <div class="d-flex my-3 my-lg-5">
                            <div>
                                <img src="/images/email.png" alt="email" />
                            </div>
                            <div class="ms-2">
                                <h5 class="ms-3 ">Email</h5>
                                <h4 class="contact-detail"><a href="mailto:contact@dforcepower.com">contact@dforcepower.com</a></h4>
                            </div>
                        </div>
                        <div class="d-flex my-3 my-lg-5">
                            <div>
                                <img src="/images/location.png" alt="location" />
                            </div>
                            <div class="ms-2">
                                <h5 class="ms-3">Location</h5>
                                <h4 class="contact-detail">Nr. Kanya Chatralay, Botad-Lathidad Road, Lathidad, Botad(Gujarat) - 364710</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ========== Map Details =========== */}
            <section>
                <div class="mapouter">
                    <div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0"
                        marginwidth="0"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3694.671404682865!2d71.66362371532624!3d22.176576752977766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3958ddfa401ce753%3A0xc6093b62780a7248!2sD&#39;FORCE%20POWER%20SOLAR%20ENERGY!5e0!3m2!1sen!2sin!4v1678275301200!5m2!1sen!2sin"></iframe><a
                            href="https://formatjson.org/">format json</a></div>
                </div>
            </section>
            {/* ============ Details ============ */}
            <Details />
            {/* =========== Footer =========== */}
            <Footer dashboard={dashboard} />
        </>
    )
}

export default ContactUs