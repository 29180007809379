import React from 'react'

const Series = ({dashboard}) => {
  return (
    // <!-- ============ Series ============= -->
    <section className="series">
      <a href={dashboard.homeImageLink} className="w-100">
            <img src={dashboard.homeBigImage} alt="product" className="img-fluid big_image" />
            <img src={dashboard.homesmallImage} alt="product" className="img-fluid small_image" />
        </a>
    </section>
  )
}

export default Series