import React from 'react'

const Applications = ({ dashboard }) => {
    return (
        // <!-- ============= Applications ============= -->
        <section id="applications" name="applications">
            {/* <!-- ------------- heading ------------- --> */}
            <div className="container my-4">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="fw-bold display-4">Applications</h2>
                    <h2 className="fw-light subheading">The Purpose of a Power Amplifier</h2>
                </div>
            </div>
            {/* <!-- ----------------------------------- --> */}
            <div className="container">
                <div className="row">
                    {
                        dashboard?.applicationImages?.map(item => {
                            return (<div className="col-lg-3 col-6 my-3 mb-2">
                                <img src={item} alt="ap" className="ap-image" />
                            </div>)
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Applications