import React from 'react'

const Heading = ({heading,subHeading}) => {
    return (
        <>
            {/* <!-- ------------- heading ------------- --> */}
            <div className="container my-4" >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <h2 className="fw-bold display-4 ">{heading}</h2>
                    <h2 className="fw-light text-center subheading">{subHeading}</h2>
                </div>
            </div>
            {/* <!-- ----------------------------------- --> */}
        </>
    )
}

export default Heading