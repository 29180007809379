import React, { useEffect } from 'react'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Privacy = ({dashboard}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar></Navbar>
            <div className="container">
                <div className="my-4">
                    <h1 className='text-center my-5'>Privacy Policy</h1>
                </div>
                <p>We, at dforcepower.com, know that you care how information about you is used and shared and we appreciate the trust you place in us, knowing that we will share your information carefully and sensibly. By visiting dforcepower.com, you are in tacit agreement with the practices described in this Privacy Policy.</p>

                <p>We may disclose your personal information:</p>

                <p>To other companies within our group If we sell our business To agents, service providers, sellers, and partners In cases where we are required by law to pass on information If we believe such an action is necessary to prevent fraud, cyber crime or to protect the website, rights, and personal safety of a person(s) To our website (customers and sales), where we may disclose aggregate statistics about visitors to describe our services to prospective partners (advertisers and sponsors) and other reputable third parties Note: These statistics will not include any personally identifiable information.</p>

                <p>- What personal information does dforcepower.com gather about its customers?</p>

                <p>The information we glean from our customers enables us to continually improve and personalize your shopping experience.</p>

                <p>dforcepower.com collects information about you from different sources. Some of these are:</p>

                <p>Information you give us: We receive and store information you enter on our website or give us in any other way. You can choose not to provide us with certain information, but then, you may not be able to take advantage of many of our features. The information we collect from you is used to respond to your requests, customise your shopping experience, improve our stores, and communicate with you. Automatic Information: We receive such information whenever you interact with our website. We use cookies and obtain certain types of information when your web browser accesses dforcepower.com. We also gather information about you when you visit advertisements and other content on other websites that are served by or on behalf of dforcepower.com.  Emails: We ensure that we make emails more useful and interesting and personalized for you. We also compare our customer lists to lists received from other companies. You can choose not to receive an email from us by adjusting your preferences. Information from other sources: We may receive information about you from other sources and add it to our account information. - With whom do we share the information that we receive?</p>

                <p>We do not share your information with just anybody. Your information is an important part of our business and we are not in the business of selling it to others. We share information only as mentioned and to third-parties that are either subject to this Privacy Notice or then, that follow practices that are as detailed and protective as this Privacy Notice.</p>

                <p>- Third-Party Service Providers</p>

                <p>dforcepower.com has several companies and individuals that provide different functions on our behalf. This includes fulfilling orders, delivering packages, sending postal mail, sending email, analyzing data, providing marketing assistance, processing credit card payments, and providing customer service. These companies and individuals have access to your personal information, which is needed to perform their functions. They may not, however, use it for other purposes.</p>

                <p>? - Promotional Offers</p>

                <p>There are times that dforcepower.com sends offers to selected groups of our customers on behalf of other businesses. However, we do not give that business your name and address.</p>

                <p> - Protection of dforcepower.com and others:</p>

                <p>We do not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes. We follow the commitments set forth in this Privacy Policy to the letter. We only release your account and other personal information when we believe this is necessary to comply with the law, or to protect dforcepower.com and its partners.</p>
            </div>
            <Details></Details>
            <Footer dashboard={dashboard}></Footer>
        </>
    )
}

export default Privacy