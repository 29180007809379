import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { singleProduct } from '../api'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const ProductDetail = ({ dashboard }) => {
    const [productDetailStatus, setProductDetailStatus] = useState("Description");
    const [product, setProduct] = useState({})
    const [bigImg, setBigImg] = useState("")
    const { slug } = useParams()
    const navigate = useNavigate()
    const changeDetailStatus = (status) => {
        setProductDetailStatus(status)
    }

    useEffect(() => {
        setBigImg(product?.photos && product?.photos[0])
    }, [product])

    useEffect(() => {

        const callNow = async () => {
            const { data } = await singleProduct(slug)
            if (data.success) {
                setProduct(data.data)

            } else {
                console.log(data);
                navigate('/')
            }
        }
        callNow();
    }, [])
    useEffect(() => {
        if (productDetailStatus == "Description") {
            document.getElementById('description').innerHTML = product?.description
        }
    }, [product, productDetailStatus])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* ----------Navbar ----------- */}
            <Navbar />
            {/* <!-- ======== Product start ========== --> */}
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 my-3">
                            <div className="d-flex">
                                <div className="small-image">
                                    {
                                        product?.photos?.map(item => {
                                            return (
                                                <div className="small-image-asset" key={item._id}>
                                                    <img src={item} alt="speaker_img_1" className="img-fluid" onClick={e => setBigImg(item)} onMouseEnter={e => setBigImg(item)} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="big-image">
                                    <img src={bigImg} alt="speaker_img_1" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h1 className="fw-bold my-2">{product?.title}</h1>
                            <h5 className="lh-base fw-normal">
                                {product?.shortDescription}
                            </h5>
                            <h5 className="mt-3 fw-noraml"><span className="fw-bold h4">Category:</span> {product.category?.name}</h5>
                            <button className="enquire-btn w-100" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now</button>
                            <h5 className="text-center mt-2 fw-bold">Say hello to your daily soundtrack!</h5>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ======= Product Details ====== --> */}
            <section>
                {/* <!-- ------------ Product Details Tab --------------- --> */}
                <div className="container">
                    <ul className="product-details-nav">
                        <li className={`${productDetailStatus == 'Description' ? "active" : ""}`} onClick={e => changeDetailStatus('Description')}>Description</li>
                        <li className={`${productDetailStatus == 'Features' ? "active" : ""}`} onClick={e => changeDetailStatus('Features')}>Features</li>
                        <li className={`${productDetailStatus == 'Specifications' ? "active" : ""}`} onClick={e => changeDetailStatus('Specifications')}>Specifications</li>
                    </ul>
                </div>
                {/* <!-- ------------------------------------------------- --> */}
                <div className="product-tab-details">
                    {/* <!-- ------------- Description --------------- --> */}
                    {
                        productDetailStatus == 'Description' ?
                            <div className="container" id="description">
                            </div>
                            : ""
                    }
                    {/* <!-- ------------------ --> */}
                    {/* <!-- --------------- features --------------- --> */}
                    {
                        productDetailStatus == 'Features' ?
                            <div className="container">
                                {
                                    product?.features?.big?.map(item => {
                                        return <img src={item} alt="product" className="img-fluid big_image feature" key={item} />
                                    })
                                }
                                {
                                    product?.features?.small?.map(item => {
                                        return <img src={item} alt="product" className="img-fluid small_image feature" key={item} />
                                    })
                                }
                            </div> : ""
                    }
                    {/* <!-- ----------------- --> */}
                    {/* <!-- ----------------- Specification --------------- --> */}
                    {
                        productDetailStatus == 'Specifications' ?
                            <div className="container">
                                {
                                    product?.spacification?.map(item => {
                                        return (
                                            <div key={item._id}>
                                                <div className="row my-2 mt-4" >
                                                    <div className="col-md-3">
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <h5>{item.value}</h5>
                                                    </div>
                                                </div>
                                                <hr className="mb-4" />
                                            </div>
                                        )
                                    })
                                }
                            </div> :
                            ""
                    }
                    {/* <!-- ----------------------------------------------- --> */}
                </div>
                {
                    product?.lastBanner && 
                    <>
                        <div className="lastImage-products big_image">
                            <img src={product?.lastBanner[0]} alt="product" />
                        </div>
                        <div className="lastImage-products small_image">
                            <img src={product?.lastBanner[1]} alt="product" />
                        </div>

                    </>
                }
            </section>
            {/* --------- Details --------- */}
            <Details />
            {/* --------- Footer --------- */}
            <Footer dashboard={dashboard} />
        </>
    )
}

export default ProductDetail